html,
body {
  height: 100%;
  font-family: "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN,
    HiraKakuProN-W3, Yu Gothic, sans-serif;
  color: #393939;
}

input,
select,
button {
  font-family: "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN,
    HiraKakuProN-W3, Yu Gothic, sans-serif !important;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
